import React from "react";
//import { graphql } from "gatsby";
import Layout from "~/components/layout";
//import Card from "~/components/styled/card";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
//import Moment from "react-moment";
import open from "~/images/Open-Quote.svg";
import close from "~/images/End-Quote.svg";
import { Link } from "gatsby";
//import stars from "~/images/stars-4.5.svg";
//import tustbadge from "~/images/trust-badge.png";
import ReviewsCarouselAbout from "../../components/ReviewsCarouselAbout";

const OurCompany = ({ data }) => {
	const stats = [
		{
			id: 1,
			name: "Cycling adventurers",
			value: "5000+ ",
		},
		{ id: 2, name: "Destinations", value: "20+ " },
		{
			id: 3,
			name: "Thoughtfully crafted bike tours",
			value: "100+ ",
		},
	];
	// const rev = data.allStrapiReview.edges;
	// const allNews = rev.map(({ node }) => node);

	// const [list, setList] = useState([...allNews.slice(0, 3)]);

	// const [loadMore, setLoadMore] = useState(false);

	// const [hasMore, setHasMore] = useState(allNews.length > 3);

	// const handleLoadMore = () => {
	// 	setLoadMore(true);
	// };

	// useEffect(() => {
	// 	if (loadMore && hasMore) {
	// 		const currentLength = list.length;
	// 		const isMore = currentLength < allNews.length;
	// 		const nextResults = isMore
	// 			? allNews.slice(currentLength, currentLength + 3)
	// 			: [];
	// 		setList([...list, ...nextResults]);
	// 		setLoadMore(false);
	// 	}
	// }, [loadMore, hasMore]);

	// useEffect(() => {
	// 	const isMore = list.length < allNews.length;
	// 	setHasMore(isMore);
	// }, [list]);

	return (
		<Layout>
			<GatsbySeo
				title="About Us: Expert-led Bicycle Tour Company"
				description="About company, story and mission of Art of Bicycle Trips."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com/about-us/our-company",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/about-us/our-company",
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/about-us/our-company",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com/about-us/our-company",
				// 	},
				// ]}
			/>

			{/* hero section */}
			<section>
				<div className="relative overflow-hidden bg-white">
					<div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
						<div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
							<div className="sm:max-w-lg">
								<h1 className="text-4xl uppercase font-bold tracking-tight text-primary sm:text-6xl">
									Our Mission
								</h1>
								<p className="mt-4 text-xl ">
									Enriching the lives of travelers and local communities.
								</p>
							</div>
							<div>
								<div className="my-20">
									{/* Decorative image grid */}
									<div
										aria-hidden="true"
										className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
									>
										<div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
											<div className="flex items-center space-x-6 lg:space-x-8">
												<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
													<div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
														<StaticImage
															className="h-full w-full object-cover object-center"
															src="../../images/cambodia-bike-tour-6.jpeg"
															alt="cycling adventures"
															loading="lazy"
														/>
														{/* <img
															src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-01.jpg"
															alt=""
															className="h-full w-full object-cover object-center"
														/> */}
													</div>
													<div className="h-64 w-44 overflow-hidden rounded-lg">
														<StaticImage
															className="h-full w-full object-cover object-center"
															src="../../images/vietnam-bike-tour-4.jpeg"
															alt="cycling adventures"
															loading="lazy"
														/>
													</div>
												</div>
												<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
													<div className="h-64 w-44 overflow-hidden rounded-lg">
														<StaticImage
															className="h-full w-full object-cover object-center"
															src="../../images/jordan-cycling.jpeg"
															alt="cycling adventures"
															loading="lazy"
														/>
													</div>

													<div className="h-64 w-44 overflow-hidden rounded-lg">
														<StaticImage
															className="h-full w-full object-cover object-center"
															src="../../images/rajasthan-bike-tour-4.jpg"
															alt="cycling adventures"
															loading="lazy"
														/>
													</div>
													<div className="h-64 w-44 overflow-hidden rounded-lg">
														<StaticImage
															className="h-full w-full object-cover object-center"
															src="../../images/rajasthan-bike-tour-23.jpg"
															alt="cycling adventures"
															loading="lazy"
														/>
													</div>
												</div>
												<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
													<div className="h-64 w-44 overflow-hidden rounded-lg">
														<StaticImage
															className="h-full w-full object-cover object-center"
															src="../../images/east-hokkaido-cycling-east-coast.jpeg"
															alt="cycling adventures"
															loading="lazy"
														/>
													</div>
													<div className="h-64 w-44 overflow-hidden rounded-lg">
														<StaticImage
															className="h-full w-full object-cover object-center"
															src="../../images/cambodia-kep-cycling2.jpg"
															alt="cycling adventures"
															loading="lazy"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* <a
										href="#"
										className="inline-block rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-center font-medium text-white hover:bg-indigo-700"
									>
										Shop Collection
									</a> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <section className="relative lg:pb-32 xl:pb-32 bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="max-w-7xl mx-auto">
					<div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
						<div className="mt-10 mx-auto lg:mx-0 max-w-xl sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
							<div className="sm:text-center  lg:text-left">
								<h1 className=" text-gray-900 lg:text-3xl  xl:text-4xl">
									<span className="block   xl:inline font-normal">About</span>{" "}
									<span className="block text-primary xl:inline font-semibold"></span>{" "}
									<span className="block text-primary  xl:inline font-normal">
										Art of Bicycle Trips
									</span>{" "}
								</h1>

								<p className="mt-3 mb-10 leading-relaxed sm:mt-5 sm:max-w-md sm:mx-auto md:mt-5 lg:mx-0">
									Enriching the lives of travelers and local communities.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 ">
					<div className="relative">
						<StaticImage
							className="rounded-lg shadow-md transform border border-gray-200"
							src="../../images/pankaj-mangal.jpeg"
							alt="cycling adventures"
							layout="FULL_WIDTH"
							aspectRatio="1.2"
							loading="lazy"
						/>
					</div>
				</div>
			</section> */}

			<section className="mx-4 my-16 lg:my-28 lg:mx-24">
				<h2 className="text-center font-bold mb-20 text-xl sm:text-3xl lg:text-4xl">
					<span className="text-primary">Welcome to Art of Bicycle Trips</span>{" "}
					<br /> Your expert-led cycling holiday company
				</h2>
				<div className="flex">
					<div className="w-20 h-20">
						<img src={open} alt="open quote" />
					</div>
					<div>
						<p className="prose prose-lg sm:prose-xl lg:prose-2xl px-2 md:px-4">
							Founded in 2010 during a 100km bumpy ride in India, Art of Bicycle Trips
							specializes in crafting distinctive cycling and e-bike adventures to some
							of the world's most breathtaking destinations. We’re all about small and
							local details.
						</p>
					</div>
					<div className="w-20 h-20">
						<img src={close} alt="close quote" />
					</div>
				</div>
			</section>

			{/* <div className="my-20">
				<h1 className="font-bold">About Us</h1>
				<h2 className="font-light mt-2 text-primary mb-14">
					Enriching the lives of travelers and local communities
				</h2>
			</div>
			<div className="">
				<StaticImage
					className="rounded-lg shadow-md transform"
					src="../../images/pankaj-mangal.jpeg"
					alt="cycling adventures"
					layout="FULL_WIDTH"
					aspectRatio="2.12"
					loading="lazy"
				/>
			</div> */}

			<section className="mb-36">
				<div className="">
					<div className="mx-auto max-w-7xl px-6 lg:px-8">
						<dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
							{stats.map((stat) => (
								<div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
									<dt className=" leading-7 lg:text-lg">{stat.name}</dt>
									<dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
										{stat.value}
									</dd>
								</div>
							))}
						</dl>
					</div>
				</div>
				{/* <div className="my-20"> */}
				{/* <h1 className="font-bold">About Us</h1>
				<h2 className="font-light text-primary max-w-3xl">
					Enriching the lives of travelers and local communities
				</h2> */}

				{/* <p className="prose prose-sm sm:prose lg:prose-lg my-10">
					Founded in 2010 during a 100km bumpy ride in India, Art of Bicycle Trips
					specialize in crafting unique cycling journeys to India and Asia-at-Large.
					Each day is crafted with utmost care so that you are well-rested; get a
					drift of local culture and cuisine and enjoy the splendid trails at your
					own pace.
				</p>
				<p className="prose prose-sm sm:prose lg:prose-lg my-10">
					With our core focus on cycling, we create and deliver exceptional cycling
					experiences across Asia. We work with some of the finest local specialists
					based in your tour region as co-creators from itinerary designing to
					on-ground operations so that you experience the destination from a local
					perspective. We’re all about small and local details.
				</p> */}
				{/* <h2 className="mt-20  mb-4">Our Story</h2> */}
				{/* <div className=" max-w-2xl">
					<div className="">
						<StaticImage
							className="rounded shadow-lg transform"
							src="../../images/pankaj-mangal.jpeg"
							alt="pankaj mangal"
							layout="FULL_WIDTH"
							aspectRatio="1.8"
							loading="lazy"
						/>
					</div>
				</div> */}
				{/* <p className="prose prose-sm sm:prose lg:prose-lg my-5">
						Our journey began on a 100km bicycle ride to a remote fishing camp,
						immersing me in the great outdoors, where I was struck by the sheer joy of
						exploring the world on two wheels, powered by nothing but my own strength.
					</p>
					<p className="prose prose-sm sm:prose lg:prose-lg my-10">
						Inspired by this realization, we founded Art of Bicycle Trips with a focus
						on thoughtfully designed itineraries, handpicked hotels, top-quality gear,
						and expert guides. We aim to create meaningful connections between you and
						the destination, while also supporting and contributing to the well-being
						of local communities.
					</p>

					<p className="prose prose-sm sm:prose lg:prose-lg my-10">
						Driven by our passion for exploration and meticulous attention to detail,
						Art of Bicycle Trips sets itself apart by curating unforgettable cycling
						adventures that immerse you in the heart of each destination. With our
						commitment to personalized experiences, sustainable travel, and genuine
						connections, we promise a truly unique and enriching experience!
					</p>

					<Link
						to="/tours/"
						className="bg-white border bottom-1 border-black text-black tracking-wide uppercase px-6 py-2 rounded"
					>
						Explore Trips
					</Link> */}

				{/* <p className="prose prose-sm sm:prose lg:prose-lg my-10">
					Inspired by this realization, we founded Art of Bicycle Trips, a boutique
					active travel company with an initial focus on providing unforgettable
					cycling journeys. We aim to offer our customers a one-of-a-kind travel
					experience that combines adventure, culture, and personal enrichment.
				</p>

				<p className="prose prose-sm sm:prose lg:prose-lg my-10">
					Our core focus on cycling allows us to deliver exceptional experiences that
					showcase the best of Asia & beyond. We work closely with some of the finest
					local specialists in each tour region to co-create every aspect of your
					itinerary, from designing to on-ground operations. This ensures that you
					get to experience the destination from a local perspective, with a keen
					attention to small and meaningful details.
				</p>
				<p className="prose prose-sm sm:prose lg:prose-lg my-10">
					We're incredibly proud of our loyal global clientele, and are recommended
					by 98% of our guests. This level of satisfaction is a testament to the
					great care that we take in designing our tours and feel a sense of pride in
					the thoughtfulness put into them.
				</p>
				<p className="prose prose-sm sm:prose lg:prose-lg my-10">
					At Art of Bicycle Trips, we believe that cycling is the perfect way to
					explore the world around us. We're passionate about sharing our love for
					cycling and the beauty of the world with you, and we're committed to
					providing you with an unforgettable adventure that leaves you with lasting
					memories.
				</p> */}
				{/* <h3 className="mt-20 text-primary mb-4 capitalize">
					Our guiding principles and business ethics
				</h3>
				<div className="prose prose-sm sm:prose lg:prose-lg">
					<ul>
						<li>
							We believe cycling and hiking are a catalyst for a new paradigm based on
							the human traits of integrity, empathy, and transparency, and encourage
							people to follow along the same lines.
						</li>
						<li>
							We are committed to being honest, transparent, and accurate when we
							communicate with customers, whether in advertising, product information,
							or more detailed travel outline.
						</li>
						<li>
							We are committed to proactively dealing with issues and complaints if
							things go wrong and will make every effort to make it right.
						</li>
						<li>
							We believe in small-scale units, communal ownership, and regional
							workplaces utilising local labour and resources.
						</li>
						<li>
							We put sustainability at the core of business decisions and strive to
							achieve sustainable profitability. Sustainable profitability for a
							business means that an organisation provides a service or product that is
							both profitable and environmentally friendly.
						</li>
						<li>
							We care about our planet and ask questions about the cost in human terms,
							in health and environment, through our actions.
						</li>
					</ul>
				</div> */}
				{/* </div> */}
			</section>
			{/* Our Story */}
			<section>
				<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
					<div className="lg:w-1/2 lg:pr-8">
						<StaticImage
							className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
							src="../../images/pankaj-mangal.jpeg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
					<div className="lg:w-1/2 lg:pl-6">
						<h2 className="text-2xl font-semibold mb-4 mt-4 lg:mt-0 uppercase">
							Our Story
						</h2>
						<p className="text-gray-700">
							Our journey started when our founder, Pankaj Mangal, set out one morning
							for a 100km ride to a remote fishing camp. He returned surprised,
							enthralled by the outdoors, and moved by the simplicity of countryside
							living. The sheer joy of exploring the world on two wheels, powered by
							nothing but his own strength, left a profound impact on him.
						</p>
						<br />
						<p className="text-gray-700">
							Inspired by this realization, he founded Art of Bicycle Trips,
							specializing in crafting distinctive cycling and e-bike adventures around
							the world. We prioritize authentic local experiences that enable you to
							form meaningful connections with the destination, all while contributing
							to the well-being of local communities.
						</p>
					</div>
				</div>
				<div className="flex justify-center items-center ">
					<div className="w-full md:w-1/2 lg:w-1/2 p-4">
						<div className="bg-white text-center">
							<p className=" text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-medium">
								"The profound transformation I underwent during my inaugural cycling
								journey compelled me to bestow this same enriching experience upon
								fellow travelers."
							</p>
						</div>
					</div>
				</div>
				{/* <div className="mt-8">
					<Link
						to="/contact-us/"
						className="bg-white border bottom-1 border-black text-black tracking-wide uppercase px-6 py-2 rounded"
					>
						Get in touch
					</Link>
				</div> */}
			</section>

			{/* Your People. Your Ride. */}
			<section className="my-28">
				<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
					<div className="lg:w-1/2  lg:pr-8">
						<h2 className="text-2xl font-semibold mb-4 uppercase">
							Your People. Your Ride.
						</h2>
						<p className="text-gray-700">
							At Art of Bicycle Trips, we believe that every cyclist is as unique as
							the journey itself. That's why we've crafted a collection of cycling
							adventures that puts you, the cyclist, at the heart of the experience.
						</p>
						<br />
						<p className="text-gray-700">
							When you set out on one of our cycling journeys, you're not just joining
							a tour – you're creating a journey that reflects your passions,
							preferences, and aspirations. Whether you're an avid cyclist seeking
							challenging terrains or a group of leisurely explorer looking to soak in
							the scenery by bike, our personalized approach ensures that your
							adventure is a true reflection of you.
						</p>
						<br />
						<p className="text-gray-700">
							And, as our name suggests, it is our constant endeavor to raise each
							bicycle trip to the level of an art form, and we strive to apply this
							aesthetic principle to every element of your bike tours.
						</p>
					</div>
					<div className="lg:w-1/2 lg:pl-6 mt-4 lg:mt-0">
						<StaticImage
							className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
							src="../../images/branded-abt1.jpeg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
				</div>

				{/* <div className="">
					<Link
						to="/tours/"
						className="bg-white border bottom-1 border-black text-black tracking-wide uppercase px-6 py-2 rounded"
					>
						Explore Trips
					</Link>
				</div> */}
			</section>

			{/* our philosophy */}
			<section className="mb-10">
				<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:pb-8">
					<div className="lg:w-1/2 lg:pr-8">
						<StaticImage
							className="mx-auto lg:mx-0 lg:w-full rounded-lg shadow-lg"
							src="../../images/rajasthan-bike-tour-8.jpg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
					<div className="lg:w-1/2 lg:pl-6">
						<h2 className="text-2xl font-semibold mb-4 mt-4 lg:mt-0 uppercase">
							Our Philosophy
						</h2>
						<p className="text-gray-700">
							Our guiding philosophy is inspired by the French notion of joie de vivre
							and the Japanese concept of Ikigai.{" "}
							<span className="font-style: italic">Joie de vivre</span> translates into
							the joy of living, a cheerful, childlike enjoyment of life.{" "}
							<span className="font-style: italic">Ikigai</span>, on the other hand,
							means a passion that gives value and joy to life, a reason for being. The
							Japanese believe that Ikigai is the secret to living a long and happy
							life. Together, these two notions encapsulate what we at Art of Bicycle
							Trips believe in.
						</p>
						<br />
					</div>
				</div>
			</section>

			<div className="flex space-x-4 mt-32 lg:mt-0">
				<Link
					to="/tours/?utm_source=about-us&utm_medium=cta-link&utm_campaign=find-trip"
					className="bg-primary tracking-wide font-medium text-white px-9 py-3 md:py-4 md:px-11 rounded-full"
				>
					Find trip
				</Link>
				<Link
					to="/design-trip/?utm_source=about-us&utm_medium=cta-link&utm_campaign=design-trip"
					className="bg-white border font-medium tracking-wide  border-primary text-primary px-6 py-3 md:py-4 md:px-8 rounded-full"
				>
					Design trip
				</Link>
			</div>
			{/* <section>
				<h2 className="mt-20 mb-10 text-2xl uppercase">Meet The Founder</h2>
				<div className="flex flex-col md:flex-row items-center md:justify-between md:pb-8">
					<div className="md:w-1/2 md:pr-8">
						<StaticImage
							className="mx-auto md:mx-0 md:w-full rounded-lg shadow-lg"
							src="../../images/pankaj-mangal.jpeg"
							alt="pankaj mangal"
							loading="lazy"
						/>
					</div>
					<div className="md:w-1/2 md:pl-6">
						<h3 className="text-2xl font-semibold my-4">Pankaj Mangal</h3>
						<p className="text-gray-700">
							Having undergone the rigmarole of meeting rooms and pie charts, Pankaj
							savors nothing but hot cups of tea and some local gossip with villagers
							in faraway places. The last many years have been largely on his set of
							wheels, since he set out to explore the world by bike - the long bumpy
							rides, traversing miles of isolated roads and sharing stories with
							strangers has been absolutely worth it!
						</p>
					</div>
				</div>
				<div className="flex justify-center items-center ">
					<div className="w-full md:w-1/2 lg:w-1/2 p-4">
						<div className="bg-white text-center">
							<p className=" text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-medium">
								"The profound transformation I underwent during my inaugural cycling
								journey compelled me to bestow this same enriching experience upon
								fellow travelers."
							</p>
						</div>
					</div>
				</div>
				<div className="mt-8">
					<Link
						to="/contact-us/"
						className="bg-white border bottom-1 border-black text-black tracking-wide uppercase px-6 py-2 rounded"
					>
						Get in touch
					</Link>
				</div>
			</section> */}
			{/* reviews */}
			<section id="reviews" className="my-16 md:my-36 overflow-hidden">
				<h2 className="mb-10 uppercase text-primary">
					Cyclists Love Us, You Will Too.
				</h2>

				{/* Reviews */}

				<ReviewsCarouselAbout />
				{/* <div className="text-center py-8">
					<a
						href="https://www.trustpilot.com/review/artofbicycletrips.com"
						target="_blank"
						rel="noopener noreferrer"
						className=""
					>
						<div className="text-center md:flex md:justify-center">
							<div className="flex flex-row justify-center items-center">
								<p className="text-base md:text-lg font-semibold">Excellent</p>
								<img
									src={stars}
									alt="Trustpilot Logo"
									className="w-24 h-auto md:w-28 md:h-auto ml-4 inline-block"
								/>
							</div>
							<div className="flex flex-row mt-1 md:mt-0 justify-center items-center">
								<p className="text-sm md:text-sm font-normal md:ml-3">
									Based on {allNews.length} reviews on
								</p>
								<img
									src={tustbadge}
									alt="Trustpilot Logo"
									className="w-auto h-6 md:w-auto md:h-6 ml-2 inline-block"
								/>
							</div>
						</div>
					</a>
				</div> */}

				{/* Load more start*/}

				{/* <div>
					{allNews &&
						list.map((reviews) => {
							return (
								<div key={reviews.id} className="container px-4 mx-auto">
									<div className="mb-4 shadow-lg rounded-2xl overflow-hidden">
										<div className="pt-3 pb-3 md:pb-3 px-4 md:px-16 bg-bgtrust">
											<div className="flex flex-wrap items-center">
												<p className="w-full text-xl text-trust md:w-auto font-medium">
													{reviews.name} from {reviews.location}
												</p>
												<div className="w-full md:w-px h-2 md:h-8 mx-8 bg-transparent md:bg-gray-200"></div>
												<span className="mr-4 text-xl text-trust font-heading font-medium">
													5.0
												</span>
												<div className="inline-flex">
													<span className="inline-block mr-1">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
													<span className="inline-block mr-1">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
													<span className="inline-block mr-1">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
													<span className="inline-block mr-1">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
													<span className="inline-block text-gray-200">
														<svg
															width="20"
															height="20"
															viewbox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"
																fill="#125960"
															></path>
														</svg>
													</span>
												</div>
											</div>
										</div>
										<div className="px-4 overflow-hidden md:px-16 pt-8 pb-8 bg-white">
											<div className="flex flex-wrap">
												<div className="w-full md:w-2/3 mb-6 md:mb-0">
													<p className="font-semibold mb-2 text-lg capitalize">
														{reviews.title}
													</p>
													<p className="prose prose-sm max-w-2xl leading-loose">
														{reviews.detailed}
													</p>
												</div>
												<div className="w-full md:w-1/3 text-right">
													<Moment
														className="mb-auto text-sm text-gray-400"
														format="MMM Do YYYY"
													>
														{reviews.date}
													</Moment>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
				</div>
				<div className="text-center">
					{hasMore ? (
						<button
							onClick={handleLoadMore}
							className=" w-auto h-full py-3 px-6 md:py-4 md:px-8 font-medium text-white bg-trust rounded-full"
						>
							Load more
						</button>
					) : (
						<p className="text-white uppercase">End of reviews</p>
					)}
				</div> */}
				{/* Load more end  */}
			</section>
		</Layout>
	);
};

// export const query = graphql`
// 	query ReviewsQuery {
// 		allStrapiReview(sort: { fields: date, order: DESC }) {
// 			edges {
// 				node {
// 					id
// 					name
// 					location
// 					date
// 					title
// 					detailed
// 				}
// 			}
// 		}
// 	}
// `;

export default OurCompany;
